









































import FilterFabric from '@/views/chat/filters/Filters/FilterFabric'
import { ExtraSettingsMenuItems, FilterConfig, FilterTypes } from '@/views/chat/filters/FilterTypes'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import FilterLvlIcon from '@/components/chat/FIlterLvlIcons/FilterLvlIcon.vue'
import Api from '@/includes/logic/Api'
import PageTitle from '@/components/PageTitle.vue'
import { errorNotification } from '@/includes/NotificationService'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'

import Component from 'vue-class-component'
import { Location } from 'vue-router'
import { cloneDeep } from 'lodash'
import Vue from 'vue'

@Component({
  components: {
    PageTitle,
    CenteredColumnLayout,
    FilterLvlIcon,
    ConfigField
  }
})
export default class NewFilterSetup extends Vue {
  filterConfig: FilterConfig | null = null

  currentView: ExtraSettingsMenuItems | null = null

  filtersListRoute: Location = {
    name: 'filters',
    params: {
      [EntityTypes.CHAT_ID]: this.$store.state.chatState.chat.chat_id
    }
  }

  get makeFilterConfig(): Record<string, any> {
    const configKey = this.filterType
    const actualConfig = cloneDeep(this.$store.state.chatState.configCopy)

    const ret = { [configKey]: actualConfig[configKey] }

    if (this.filterConfig && this.filterConfig.advancedFieldNames && this.filterConfig.advancedFieldNames.length > 0) {
      this.filterConfig.advancedFieldNames.forEach(field => {
        ret[field] = actualConfig[field]
      })
    }

    return cloneDeep(ret)
  }

  get filterType(): FilterTypes {
    return this.$route.params.filterName.toString() as FilterTypes
  }

  enableFilterInMainState(filterKey: FilterTypes): void {
    this.$store.commit('setObjectField', {
      object: this,
      objectPath: `$store.state.chatState.chat.config.${ filterKey }.enabled`,
      value: true,
    })
  }

  saveFilterSettings(): void {
    this.$baseTemplate.loader.open()

    Api.setChatConfigPartial('tg', {
      chat_id: parseInt(this.$store.state.chatState.chat.chat_id),
      config: { config: this.makeFilterConfig }
    })
      .then(() => {
        this.$store.dispatch('mergeConfigsAction')
          .then(() => this.$router.push(this.filtersListRoute))
          .catch(errorNotification)
      })
      .catch(errorNotification)
      .finally(() => this.$baseTemplate.loader.close())
  }

  onViewUpdate(value: ExtraSettingsMenuItems | null): void {
    this.currentView = value
  }

  created(): void {
    this.enableFilterInMainState(this.filterType)
    this.$baseTemplate.saveButton.hide()
    this.$store.commit('configCopyMutation')

    try {
      this.filterConfig = FilterFabric.create(this.filterType as FilterTypes)
    } catch (e: any) {
      errorNotification(e)
      this.$router.push({ name: 'new_filters' })
    }
  }

  destroyed() {
    this.$baseTemplate.saveButton.show()
  }
}
